/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState } from 'react';
import AudioContext from './audioContext';
import type { AudioArgs } from './audioContext';

const AudioProvider: React.FC = ({ children }) => {
  const updateAudio = (isOn: boolean) => {
    setAudio((prevState) => {
      return {
        ...prevState,
        isAudioOn: isOn,
      };
    });
  };

  const audioState: AudioArgs = {
    isAudioOn: false,
    setIsAudioOn: updateAudio,
  };

  const [audio, setAudio] = useState(audioState);

  return (
    <AudioContext.Provider value={audio}>{children}</AudioContext.Provider>
  );
};

export default AudioProvider;
