import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import Router from 'next/router';
import * as Sentry from '@sentry/node';
import type { AppProps } from 'next/app';

import { pageview } from '@/utils/gtag';
import Provider from '@/components/Provider';

Sentry.init({
  enabled: process.env.SENTRY_ENVIRONMENT !== 'localhost',
  dsn: process.env.SENTRY_DSN,
  environment: process.env.SENTRY_ENVIRONMENT,
});

const App = ({
  Component,
  pageProps,
  router,
  err,
}: AppProps & { err: Error }) => {
  // Send pageview events to Google Analytics
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      pageview(url);
    };
    Router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  // Workaround for https://github.com/zeit/next.js/issues/8592
  const modifiedPageProps = { ...pageProps, err };

  // Dynamically import framer-motion for page transitions
  const [framer, setFramer] = useState<typeof import('framer-motion') | null>(
    null
  );
  useEffect(() => {
    // framer-motion uses Proxy which isn't supported by IE11, so test for that before importing
    if (typeof Proxy !== 'undefined') {
      const importFramer = async () => {
        setFramer(await import('framer-motion'));
      };
      importFramer();
    }
  }, []);

  return (
    <Provider>
      <Head>
        <link rel="icon" type="image/png" href="/favicon.png" />
      </Head>
      {framer === null ? (
        <Component {...modifiedPageProps} key={router.route} />
      ) : (
        <framer.AnimatePresence initial={false} exitBeforeEnter>
          <framer.motion.div
            key={router.route}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1.5 }}
            style={{ display: 'flex', width: '100%' }}
          >
            <Component {...modifiedPageProps} key={router.route} />
          </framer.motion.div>
        </framer.AnimatePresence>
      )}
    </Provider>
  );
};

export default App;
