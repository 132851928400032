import React from 'react';
import { CSSReset, ThemeProvider } from '@chakra-ui/core';
import { Global, css } from '@emotion/core';

import theme from '@/utils/theme';
import AudioProvider from '@/utils/AudioProvider';

const Provider: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <AudioProvider>
        <CSSReset />
        <Global
          styles={css`
            html {
              height: -webkit-fill-available;
            }
            body {
              display: flex;
              min-height: 100vh;
              min-height: -webkit-fill-available;

              /* On IE11 we have to add a pseudo element to force a min-height of 100vh */
              @media all and (-ms-high-contrast: none),
                (-ms-high-contrast: active) {
                &:before {
                  content: '';
                  height: 100vh;
                }
              }
            }
            #root,
            #__next {
              display: flex;
              width: 100%;
            }
            a {
              text-decoration: underline;
            }
            .sr-only {
              position: absolute;
              width: 1px;
              height: 1px;
              padding: 0;
              overflow: hidden;
              clip: rect(0, 0, 0, 0);
              white-space: nowrap;
              clip-path: inset(50%);
              border: 0;
            }
            /* https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap */
            @font-face {
              font-display: swap;
              font-family: 'Open Sans';
              font-weight: 400;
              src: local('Open Sans Regular'), local('OpenSans-Regular'),
                url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0bf8pkAg.woff2)
                  format('woff2'),
                url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-U1UQ.woff)
                  format('woff');
            }
            @font-face {
              font-display: swap;
              font-family: 'Open Sans';
              font-weight: 600;
              src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
                url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UNirkOUuhpKKSTjw.woff2)
                  format('woff2'),
                url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UNirk-Vg.woff)
                  format('woff');
            }
            @font-face {
              font-display: swap;
              font-family: 'Open Sans';
              font-weight: 700;
              src: local('Open Sans Bold'), local('OpenSans-Bold'),
                url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN7rgOUuhpKKSTjw.woff2)
                  format('woff2'),
                url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN7rg-Vg.woff)
                  format('woff');
            }
            /* https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@700&display=swap */
            @font-face {
              font-display: swap;
              font-family: 'Libre Baskerville';
              font-weight: 700;
              src: local('Libre Baskerville Bold'),
                local('LibreBaskerville-Bold'),
                url(https://fonts.gstatic.com/s/librebaskerville/v7/kmKiZrc3Hgbbcjq75U4uslyuy4kn0qviTgY3KcC-wLOj.woff2)
                  format('woff2'),
                url(https://fonts.gstatic.com/s/librebaskerville/v7/kmKiZrc3Hgbbcjq75U4uslyuy4kn0qviTjYz.woff)
                  format('woff');
            }
          `}
        />
        {children}
      </AudioProvider>
    </ThemeProvider>
  );
};

export default Provider;
